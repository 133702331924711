import React from 'react'

import { TogglesProvider } from '@acorns/web-toggles-react'
import type { TogglesProviderConfig } from '@acorns/web-toggles-react'
import { useAnalyticsClient } from '@acorns/web-utils'

export function LoginAppTogglesProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const analyticsClient = useAnalyticsClient()
  const userId = undefined // all login-app users are unauthenticated

  const togglesProviderConfig: TogglesProviderConfig = {
    sdkKey: process.env.AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY,
    userAttributes: {
      userId,
      deviceId: analyticsClient.getAnonymousId(),
    },
    waitForInitialization: true,
    allowToggleOverrides: process.env.BUILD_ENV !== 'production',
    amplitudeExperimentExposureTrackingProvider: {
      track(exposure) {
        analyticsClient.track('$exposure', {
          experiment_key: exposure.experiment_key ?? '',
          flag_key: exposure.flag_key,
          variant: exposure.variant ?? '',
        })
      },
    },
    amplitudeExperimentFlagsServerUrl:
      process.env.AMPLITUDE_EXPERIMENT_FLAGS_SERVER_URL,
    amplitudeExperimentServerUrl: process.env.AMPLITUDE_EXPERIMENT_SERVER_URL,
    amplitudeExperimentUserProvider: {
      getUser() {
        return {
          user_id: userId,
          device_id: analyticsClient.getAnonymousId(),
        }
      },
    },
  }

  return (
    <TogglesProvider {...togglesProviderConfig}>{children}</TogglesProvider>
  )
}
